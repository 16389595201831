@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this CSS to your stylesheet */

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

* {
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body {
  font-family: "Inter", sans-serif;
  padding: 0 !important;
  box-sizing: content-box;
  margin: 0 !important;
}

.light {
  background-color: #fff;
  color: #000;
}

.dark {
  background-color: #000;
  color: #fff;
}

.gradient-button {
  animation: gradientAnimation 2s linear forwards !important;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

nav {
  @apply py-3 w-full;
  z-index: 1000;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.nav-wrapper {
  @apply block lg:flex w-full lg:items-center lg:justify-between relative;
}
.nav-button-wrapper {
  @apply absolute right-1 flex;
}
.nav-button {
  @apply block lg:hidden p-2 px-4 mt-1 lg:mt-0 ml-1 cursor-pointer;
  border: solid 1px #ccc;
  border-radius: 20%;
  z-index: 10000;
}
.logo {
  @apply w-[200px] text-2xl;
}

.nav-menu {
  @apply p-0  flex-1;
}
.nav-menu div {
  @apply lg:flex;
}

.nav-menu > div > ul {
  @apply list-none block lg:flex lg:flex-row mb-2 relative mt-3 lg:mt-0;
  z-index: 100000;
}
.nav-menu > div > ul > li {
  @apply relative;
}

.nav-menu > div > ul > li {
  @apply hover:rounded-sm m-0 p-0;
}
.nav-menu > div > ul > li > a {
  @apply block px-3 py-1 lg:px-5 lg:py-3 hover:rounded-sm cursor-pointer font-semibold lg:text-lg;
}
.nav-menu > div > ul > li .submenu {
  @apply hidden 
  lg:absolute
   lg:top-full 
   lg:left-0 
   ml-4 
   lg:ml-0 
   rounded-tl-none 
   lg:hover:shadow
    shadow-lg 
    py-2 
    rounded-lg 
    lg:w-40 
    truncate;
}
.nav-menu > div > ul > li:hover .submenu {
  @apply block;
  z-index: 100000;
}

.submenu > li > a {
  @apply block
   px-2 py-1 lg:px-3 lg:py-2
   font-semibold
   rounded-lg mx-2 lg:mx-1 !important;
}
.submenu > li > a:hover {
  @apply bg-custom-blue 
   text-white;
}

.hero {
  opacity: 1 !important;
}

@keyframes expand {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}

.expand {
  overflow: hidden;
  animation: expand 2s ease-out forwards;
}
